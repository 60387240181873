import React from 'react';

interface UseQueryParams<Response> {
  fetcher: () => Promise<Response>;
  deps?: unknown[];
  execute?: boolean;
}

interface UseQueryResponse<Response> {
  result: Response | null;
  isLoading: boolean;
  error: string;
  refetchData: () => void;
}

export const useQuery = <Response>({
  fetcher,
  deps,
  execute = true,
}: UseQueryParams<Response>): UseQueryResponse<Response> => {
  const [result, setResult] = React.useState<Response | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>('');

  const fetchData = async () => {
    if (!execute) return;
    setIsLoading(true);
    try {
      const data = await fetcher();
      setError('');
      setResult(data);
    } catch (err: unknown) {
      if (typeof err === 'string') setError(err);
      else setError('Unknown error');
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(
    () => {
      if (execute) {
        setIsLoading(true);
        fetchData().finally(() => {
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    },
    deps ? [execute, ...deps] : [execute],
  );

  return { result, isLoading, error, refetchData: fetchData };
};
