import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../../../assets/theme/colors';

interface StyledButtonProps {
  selected: boolean;
}

export const StylesButton = styled(Link)<StyledButtonProps>(({ selected }) => ({
  width: '100%',
  height: '54px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  background: '#051224',
  borderBottom: selected ? '2px solid #E4F53B' : '2px solid transparent',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '130%',
  textAlign: 'center',
  color: selected ? '#E4F53B' : colors.white,
  '@media screen and (min-width: 1100px)': {
    height: '71px',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '130%',
    textTransform: 'uppercase',
  },
}));
