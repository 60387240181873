import { FC } from 'react';

import { StylesButton } from './NavigationLink.styles';
import { NavigationLinkProps } from './types';

const NavigationLink: FC<NavigationLinkProps> = ({
  children,
  selected,
  to,
  ...linkProps
}) => {
  return (
    <StylesButton selected={selected} to={to} {...linkProps}>
      {children}
    </StylesButton>
  );
};

export default NavigationLink;
