const colors = {
  primaryDarkGrey: '#262626',
  primaryGrey: '#999999',
  primaryLightGrey: '#dadada',
  secodaryDarkGrey: '#10100F',
  secondaryGrey: '#656563',
  greyInputButton: '#2D2D2C',
  greenScoreBackground: '#0CD664',
  yellowScoreBackground: '#E4F53B',
  redPlayerCardBackground: '#E9634E',
  lightGrayFont: '#808098',
  gradientBlue: 'linear-gradient(0deg, #003D75 0%, #005EB8 100%)',
  activeButtonColor: '#0064FA',
  white: '#FFFFFF',
  primaryColor: '#121FFF',
  secondaryColor: '#051224',
  buttonColor: '#E4F53B',
  cardBG: 'linear-gradient(180deg, #121FFF 0%, rgba(18, 31, 255, 0.00) 100%);',
  defendPoint: '#6B6B6B',
  greenColor: '#30E920',
  secButtonColor: '#56AAC9',
  textColor: '#111111',
  menuColor: '#121212',
};

export default colors;
