import styled, { createGlobalStyle } from 'styled-components';

export const Reset = createGlobalStyle`
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    // background: linear-gradient(0deg, #1D1D1B, #1D1D1B), #262626;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}`;

export const AppStyles = styled('div')({
  boxSizing: 'border-box',
  color: '#FFFFFF',
});
export const GlobalStyles = createGlobalStyle`


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 700px;
  overflow-y: auto;
}

body {
  // min-height:100dvh;
  font-family: "RocGrotesk";
  overscroll-behavior-y: contain;
  line-height: 1;
  box-sizing: border-box;
  color: #000000;
  // background: #121FFF;
  //   @media screen and (max-width: 1250px) {
  //},
}

,

`;
