interface Parameters {
  [key: string]: string;
}

export function triggerGTAGEvent(
  eventName: string,
  parameters?: Parameters,
): void {
  window.dataLayer.push({ event: eventName, ...parameters });
}

export function triggerGTAGInternalNavEvent(link: string) {
  triggerGTAGEvent('pg_navigation_internal_link', { link_clicked: link });
}

export function triggerGTAGExternalNavEvent(link: string) {
  triggerGTAGEvent('pg_navigation_external_link', { link_clicked: link });
}

export function triggerGTAGButtonEvent(text: string) {
  triggerGTAGEvent('button_clicked', { button_text: text });
}

export function triggerGTAGSearchEvent(text: string) {
  triggerGTAGEvent('search_filter_clicked', { search_filter_text: text });
}
