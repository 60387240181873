import { createGlobalStyle } from 'styled-components';

import Helvet from '../assets/fonts/HelveticaNeueCyr-Medium.woff2';
import RocGrotesk from '../assets/fonts/RocGrotesk.otf';
import RocGroteskLight from '../assets/fonts/RocGroteskLight.otf';
import RocGroteskMedium from '../assets/fonts/RocGroteskMedium.otf';

const FontStyles = createGlobalStyle`

 @font-face {
    font-family: "RocGrotesk";
    src: url("${RocGrotesk}") format("truetype");
    url(${RocGrotesk}) format("truetype");

    font-weight: 700;
  }
   @font-face {
    font-family: "RocGroteskLight";
    src: url("${RocGroteskLight}") format("truetype");
    url(${RocGroteskLight}) format("truetype");

    font-weight:400;
  }
    @font-face {
    font-family: "RocGroteskMedium";
    src: url("${RocGroteskMedium}") format("truetype");
    url(${RocGroteskMedium}) format("truetype");

    font-weight:500;
  }
  
`;

export default FontStyles;
