const paths: Record<string, string> = {
  home: '/home',
  guide: '/how-to-play',
  myLeagues: '/my-leagues',
  wins: '/wins',
  save: '/save',
  goals: '/goals',
  monthly: '/monthly',
  pepsi: '/pepsi',
  weekly: '/weekly',
  shutout: '/shutout',
  leaderboardScores: '/scores',
  timeout: '/timeout',
  admin: '/admin',
  gamezone: '/gamezone',
  userLeague: '/league',
  rules: '/rules',
  splashscreen: '/',
  rankings: '/ranking',
  prize: '/prize',
  howToPlay: '/how',
  calendar: '/calendar',
  faq: '/faq',
};

export default paths;
