import axios from 'axios';

import { localStorageGet } from '@/utils/localStorage';

const url = process.env.REACT_APP_BASE_URL;

export const FetchDevision = async () => {
  const getPickems2 = `${url}/pickems?offset=0&limit=3`;
  const jwtToken = localStorageGet('jwt');
  try {
    const result2 = await axios.get(getPickems2, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    const data2 = result2.data.pickems;

    return data2;
  } catch {
    throw Error('Promise failed');
  }
};

export const FetchSinglePickem = async (pickemId: number | string) => {
  const getPickem = `${url}/pickems/${pickemId}`;

  const jwtToken = localStorageGet('jwt');

  try {
    const result2 = await axios.get(getPickem, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    const data2 = result2.data.pickem;

    return data2;
  } catch {
    throw Error('Promise failed');
  }
};

export const FetchPickemList = async () => {
  const getPickemsList = `${url}/pickems?offset=0&limit=6&selections=false`;

  const jwtToken = localStorageGet('jwt');

  try {
    const result2 = await axios.get(getPickemsList, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    const data2 = result2.data.pickems;

    return data2;
  } catch {
    window.location.href = `${process.env.REACT_APP_URL_GAMEZONE}`;
    throw Error('Promise failed');
  }
};
