import styled from 'styled-components';

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  '@media screen and (min-width:1100px)': {
    // paddingTop: '15.3px',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    overflow: 'hidden',
  },
});
export const WrapperNav = styled('div')({
  width: '100%',
  maxWidth: '1440px',
  margin: 'auto',
});
