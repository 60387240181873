import styled from 'styled-components';

import colors from '../../../../assets/theme/colors';

interface LinkProps {
  isActive?: boolean;
}
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  // background: ${colors.secondaryColor};
  border-top: 1px solid #e4f53b;
  box-sizing: border-box;
  @media (min-width: 768px) {
    // background: ${colors.primaryColor};
    height: 72px;
    align-items: center;
    justify-content: center;
    max-width: 1440px;
    margin: 20px auto 0 auto;
    border-top: 1px solid #e4f53b;
  }
`;
export const LinkBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 17px 14px;
  gap: 4px;
  background: ${colors.secondaryColor};
  border-bottom: 1px solid #656563;
  box-sizing: border-box;
  @media (min-width: 768px) {
    height: 72px;
    align-items: center;
    background: ${colors.secondaryColor};
    padding: 0px 17px;
  }
`;

export const Dropdown = styled('div')({});

export const DropdownButton = styled('button')({
  color: colors.lightGrayFont,
  width: 'fit-content',
  minWidth: '116px',
  border: 'none',
});

export const DropdownListContainer = styled('div')({
  // position: 'absolute',
  display: 'flex',
  // flexDirection: 'column',
  alignItems: 'center',
  // transform: 'translateY(15px)',
  zIndex: 25,
});

export const DropdownListItem = styled('div')({
  // width: '30vw',
  backgroundColor: '#292933',
  color: 'white',
  margin: '0 auto 6px 0',
  '& button': {
    textAlign: 'left',
    justifyContent: 'flex-start !important',
  },
  '@media screen and (max-width: 768px)': {
    width: '65vw',
  },
});

export const DropdownListItemRight = styled('div')({
  margin: '6px 8px 6px 0',
  whiteSpace: 'nowrap',

  '@media screen and (min-width: 768px)': {
    fontSize: '12px',
    width: '31vw',
  },
});

export const StyledDropdownListButton = styled('button')({
  backgroundColor: 'transparent',
  color: 'white',
  display: 'inline',
  width: '100%',
  padding: '13px 8px',
  fontFamily: 'RocGroteskMedium, sans-serif',
  fontWeight: '500',
  fontSize: '18px',
  textAlign: 'center',
  borderRadius: '8px',
  border: 'none',
  cursor: 'pointer',
  userSelect: 'none',

  '@media screen and (max-width: 768px)': {
    fontSize: '12px',
    padding: '9px 8px',
  },
});

export const DropdownImageContainer = styled('div')({
  marginRight: '20px',
  '@media screen and (max-width: 768px)': {
    width: '60px',
  },
});

export const Overlay = styled('div')({
  position: 'absolute',
  zIndex: 20,
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
});

export const StyledDropdownImage = styled.img`
  display: block;
  width: 100%;
`;

export const ContainerNavLink = styled('div')<LinkProps>((props) => ({
  display: 'flex',
  columnGap: '20px',
  alignItems: 'center',
  border: props.isActive ? '3px solid transparent' : '',
  color: props.isActive ? '#051224' : '#ffffff',
  flex: '1',
  height: '37px',
  fontWeight: '700',
  background: props.isActive
    ? `${colors.buttonColor}`
    : `${colors.secondaryColor}`,
  justifyContent: 'center',
  borderRadius: props.isActive ? '8px' : '0px',
  '@media screen and (min-width: 768px)': {
    height: '45px',
  },
}));
