import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import paths from '@/AppRouter/paths';
import { getJWTToken } from '@/api/pickems';
import { IS_PRODUCTION } from '@/core/env';
import i18n from '@/i18n';

import { localStorageGet, localStorageSet } from '@/utils/localStorage';

export const useAuth = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    const lang = new URLSearchParams(location.search).get('lang');
    // localStorage.setItem('token', JSON.stringify(token));

    if (searchParams.has('lang')) {
      i18n.changeLanguage(lang || 'en');
      window.dispatchEvent(new Event('storage'));
      searchParams.delete('lang');
    }

    // if (searchParams.has('token')) {
    // const token = searchParams.get('token');
    if (token) {
      searchParams.delete('token');
      setSearchParams(searchParams);
      getJWTToken(token)
        .then(() => {
          setLoading(false);
          if (localStorage.getItem('jwt')) {
            navigate(localStorageGet('showing') ? paths.home : paths.guide);
            localStorageSet('showing', true);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // window.location.href = `https://low6-atp-auth-dev.netlify.app/login`;
            if (IS_PRODUCTION)
              window.location.href = `${process.env.REACT_APP_URL_GAMEZONE}`;
          }
        });
      // }
    } else {
      setLoading(false);
    }
  }, [location.search, navigate, searchParams, setSearchParams]);

  return loading;
};
