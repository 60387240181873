import React, { Suspense, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import LeaderBoardNavigation from '@/Components/Layout/Components/LeaderBoarbNavigation/LeaderBoardNavigation';
import Top5Navigation from '@/Components/Layout/Components/To5Navigation/Top5Navigation';
import PicksHomeNavigation from '@/Components/PicksHomeNavigation';
import { League } from '@/api/pickems';
import { MIN_DESKTOP_WIDTH } from '@/constants/common';

import { PlayersContext } from '@/context/PlayersContext';

import { RoutesEnum } from '@/enums/routes.enum';

import { useWindowSize } from '@/helpers/useWidth';

import Loader from '@/pages/Loader/Loader';
import { Wrapper } from '@/pages/MyLeaguesScreen/MyLeaguesScreen.styles';

import { ITrs } from '@/utils/types/types';

import paths from './paths';

const SplashScreenPage = React.lazy(
  () => import('../pages/SpalshScreen/SplashScreen'),
);
const LayoutPage = React.lazy(() => import('../Components/Layout'));
const RulesPage = React.lazy(() => import('../pages/Rules/Rules'));
const FaqPage = React.lazy(() => import('../pages/Faq/Faq'));
const HomeScreenPage = React.lazy(() => import('../pages/HomeScreen'));
const OnboardingScreenPage = React.lazy(
  () => import('../pages/OnboardingScreen'),
);
const MyLeagueScreenPage = React.lazy(() => import('../Components/MyLeagues'));
const OverallScreenPage = React.lazy(
  () => import('../Components/Overall/Overall'),
);
const WeaklyTableScreenPage = React.lazy(
  () => import('../Components/Weekly/WeeklyTabPage'),
);
const PlayScreenPage = React.lazy(() => import('../pages/Play/Play'));
const TimeOutScreenPage = React.lazy(
  () => import('../pages/Alerts/TimeoutAlert'),
);
const AdminLeagueScreenPage = React.lazy(
  () => import('../pages/LeaguePages/AdminLeaguePage'),
);
const UserLeagueScreenPage = React.lazy(
  () => import('../pages/LeaguePages/UserLeaguePage'),
);
const ShoutoutScoreScreenPage = React.lazy(
  () => import('../pages/PepsiShutout/ShutoutScoreReview'),
);
const RankingScreenPage = React.lazy(
  () => import('../pages/RankingAndShrudleScreen'),
);
const PrizingPage = React.lazy(() => import('../pages/Prizing'));
const HowToPlayPage = React.lazy(() => import('../pages/HowToPlayPage'));
const CalendarScreen = React.lazy(() => import('../pages/CalendarScreen'));

const pepsiPointsData = ['Shutout = 20pts'];

const AppRouter: React.FC = () => {
  const [currentViewedLeague, setCurrentViewedLeague] = useState<League>();
  const [currentWeeklyIndex, setCurrentWeeklyIndex] = useState<number>(0);
  const [currentMonthlyIndex, setCurrentMonthlyIndex] = useState<number>(0);
  const { width } = useWindowSize();
  const isDesktop = width > MIN_DESKTOP_WIDTH;
  const { allPlayersData, isLoaded } = useContext(PlayersContext);
  const pickemData = allPlayersData && allPlayersData[0];
  const questionsData = pickemData?.questions;
  const lng = localStorage.getItem('i18nextLng');
  const { t } = useTranslation();
  const trs: ITrs = t('trs', {
    returnObjects: true,
  });

  return (
    <Routes>
      <Route
        element={
          <Suspense
            fallback={
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            }
          >
            <SplashScreenPage />
          </Suspense>
        }
        path={paths.splashscreen}
      ></Route>
      <Route
        element={
          <Suspense
            fallback={
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            }
          >
            <LayoutPage />
          </Suspense>
        }
      >
        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <RulesPage />
            </Suspense>
          }
          path={paths.rules}
        />
        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <FaqPage />
            </Suspense>
          }
          path={paths.faq}
        />
        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <PrizingPage />
            </Suspense>
          }
          path={paths.prize}
        />
        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <HowToPlayPage />
            </Suspense>
          }
          path={paths.howToPlay}
        />
        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <HomeScreenPage />
            </Suspense>
          }
          path={paths.home}
        />
        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <OnboardingScreenPage />
            </Suspense>
          }
          path={paths.guide}
        />
        <Route
          element={
            isDesktop ? (
              <LeaderBoardNavigation
                currentMonthlyIndex={currentMonthlyIndex}
                currentWeeklyIndex={currentWeeklyIndex}
                periodDate={
                  lng === 'en' ? pickemData?.title_en : pickemData?.title_fr
                }
                setCurrentMonthlyIndex={setCurrentMonthlyIndex}
                setCurrentWeeklyIndex={setCurrentWeeklyIndex}
              />
            ) : (
              <PicksHomeNavigation currentPage={'leaderboard'}>
                <LeaderBoardNavigation
                  currentMonthlyIndex={currentMonthlyIndex}
                  currentWeeklyIndex={currentWeeklyIndex}
                  periodDate={
                    lng === 'en' ? pickemData?.title_en : pickemData?.title_fr
                  }
                  setCurrentMonthlyIndex={setCurrentMonthlyIndex}
                  setCurrentWeeklyIndex={setCurrentWeeklyIndex}
                />
              </PicksHomeNavigation>
            )
          }
        >
          <Route
            element={
              <Suspense
                fallback={
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                }
              >
                <Wrapper>
                  <MyLeagueScreenPage
                    setCurrentViewedLeague={setCurrentViewedLeague}
                  />
                </Wrapper>
              </Suspense>
            }
            index
            path={paths.myLeagues}
          />
          <Route
            element={
              <Suspense
                fallback={
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                }
              >
                <OverallScreenPage currentMonthlyIndex={currentMonthlyIndex} />
              </Suspense>
            }
            path={paths.monthly}
          />
          <Route
            element={
              <Suspense
                fallback={
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                }
              >
                <WeaklyTableScreenPage
                  currentPickemIndex={currentWeeklyIndex}
                />
              </Suspense>
            }
            path={RoutesEnum.weekly}
          />
          <Route
            element={
              <Suspense
                fallback={
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                }
              >
                <WeaklyTableScreenPage
                  currentPickemIndex={currentWeeklyIndex}
                />
              </Suspense>
            }
            path={RoutesEnum.weeklyPickemId}
          />
          <Route
            element={
              <Suspense
                fallback={
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                }
              >
                <AdminLeagueScreenPage currentLeague={currentViewedLeague} />
              </Suspense>
            }
            path={paths.admin}
          />
          <Route
            element={
              <Suspense
                fallback={
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                }
              >
                <UserLeagueScreenPage
                  currentViewedLeague={currentViewedLeague}
                />
              </Suspense>
            }
            path={paths.userLeague}
          />
          <Route
            element={
              <Suspense
                fallback={
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                }
              >
                <ShoutoutScoreScreenPage
                  currentWeeklyIndex={currentWeeklyIndex}
                  setCurrentWeeklyIndex={setCurrentWeeklyIndex}
                />
              </Suspense>
            }
            path={paths.leaderboardScores}
          />
          <Route
            element={
              <Suspense
                fallback={
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                }
              >
                <ShoutoutScoreScreenPage
                  currentWeeklyIndex={currentWeeklyIndex}
                  setCurrentWeeklyIndex={setCurrentWeeklyIndex}
                />
              </Suspense>
            }
            path={`${paths.leaderboardScores}/:id/:pickemId`}
          />
        </Route>

        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <PlayScreenPage
                bannerInfo={
                  lng === 'en'
                    ? questionsData && questionsData?.[0]?.title_en
                    : questionsData && questionsData[0].title_fr
                }
                bigData={pickemData && pickemData}
                isLoaded={isLoaded}
                isPepsiShutout={false}
                playerData={questionsData && questionsData?.[0]?.options}
                pointsInfo={
                  lng === 'en'
                    ? questionsData && questionsData?.[0]?.description_en
                    : questionsData && questionsData?.[0]?.description_fr
                }
                title={trs.wins}
              />
            </Suspense>
          }
          path={paths.wins}
        />
        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <PlayScreenPage
                bannerInfo={
                  lng === 'en'
                    ? questionsData && questionsData?.[3]?.title_en
                    : questionsData && questionsData?.[3]?.title_fr
                }
                bigData={pickemData && pickemData}
                isLoaded={isLoaded}
                isPepsiShutout={true}
                pointsInfo={pepsiPointsData}
                title={trs.pepsiZero}
              />
            </Suspense>
          }
          path={paths.shutout}
        />

        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <TimeOutScreenPage />
            </Suspense>
          }
          path={paths.timeout}
        />
        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <AdminLeagueScreenPage currentLeague={currentViewedLeague} />
            </Suspense>
          }
          path={paths.admin}
        />
        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <UserLeagueScreenPage currentViewedLeague={currentViewedLeague} />
            </Suspense>
          }
          path={paths.userLeague}
        />

        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <RankingScreenPage />
            </Suspense>
          }
          path={paths.rankings}
        />
        <Route
          element={
            <Suspense
              fallback={
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              }
            >
              <Top5Navigation currentPage='calendar'>
                <CalendarScreen />
              </Top5Navigation>
            </Suspense>
          }
          path={paths.calendar}
        />
      </Route>
      <Route element={<Navigate to={paths.home} />} path={'*'} />
    </Routes>
  );
};

export default AppRouter;

const LoaderContainer = styled('div')({
  width: '100%',
  height: '100dvh',
  background: '#036',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media screen and (min-width: 900)': {
    height: '400px',
  },
});
