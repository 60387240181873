import React from 'react';

import { ButtonsWrapper } from './NavigationButtons.styles';
import { NavigationButtonsProps } from './types';

const NavigationButtons: React.FC<NavigationButtonsProps> = ({ children }) => {
  return <ButtonsWrapper>{children}</ButtonsWrapper>;
};

export default NavigationButtons;
