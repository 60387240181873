import styled from 'styled-components';

import loader from './loader.svg';
import whiteLoader from './whiteLoader.svg';

const Loader = ({ color }: { color?: 'white' | 'blue' }) => (
  <StyledLoaderContainer>
    <img alt='loader' src={color === 'white' ? whiteLoader : loader} />
  </StyledLoaderContainer>
);

export default Loader;

const StyledLoaderContainer = styled('div')({
  height: '100%',
  display: 'fixed',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px',

  position: 'relative',
  top: '-25%',
});
