import styled from 'styled-components';

export const WrapperNav = styled('div')({
  width: '100%',
  maxWidth: '1440px',
  margin: 'auto',
  '@media screen and (min-width:1100px)': {
    paddingTop: '48px',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
  },
});
export const ButtonsWrapper = styled('div')({
  display: 'flex',
  '@media screen and (min-width:1250px)': {
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
  },
});
