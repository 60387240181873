import axios from 'axios';

export const fetchTournamentApi = async () => {
  const COLLECTIVE_LEADERBOARD = process.env.REACT_APP_COLLECTIVE_LEADERBOARD;
  const url = process.env.REACT_APP_BASE_URL;

  const test = `${url}/leagues/${COLLECTIVE_LEADERBOARD}/tournaments?tournament_type=MONTH`;
  try {
    const result = await axios.get(test);

    const data = result.data.tournaments;

    return data;
  } catch {
    throw Error('Promise failed');
  }
};
