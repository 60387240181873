import React from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { MIN_DESKTOP_WIDTH } from '@/constants/common';

import paths from '../../AppRouter/paths';
import { useWindowSize } from '../../helpers/useWidth';
import NavigationButtons from '../NavigationButtons';
import NavigationLink from '../NavigationButtons/Components/NavigationLink';
import { WrapperNav } from '../NavigationButtons/NavigationButtons.styles';

import { PicksHomeNavigationProps } from './types';

const PicksHomeNavigation: React.FC<PicksHomeNavigationProps> = ({
  children,
  currentPage,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isDesktop = width > MIN_DESKTOP_WIDTH;

  return (
    <>
      {isDesktop ? (
        <WrapperNav>
          {/* <NavigationButtons>
            <NavigationLink selected={currentPage === 'game'} to={paths.home}>
              {t('game')}
            </NavigationLink>
            <NavigationLink selected={currentPage === 'leaderboard'} to={paths.myLeagues}>
              {t('leaderBoard')}
            </NavigationLink>
          </NavigationButtons> */}
          {children}
          <Outlet />
        </WrapperNav>
      ) : (
        <>
          <NavigationButtons>
            <NavigationLink selected={currentPage === 'game'} to={paths.home}>
              {t('game')}
            </NavigationLink>
            <NavigationLink
              selected={currentPage === 'leaderboard'}
              to={paths.myLeagues}
            >
              {t('leaderBoard')}
            </NavigationLink>
          </NavigationButtons>
          {children}
          {/* <Outlet /> */}
        </>
      )}
    </>
  );
};

export default PicksHomeNavigation;
