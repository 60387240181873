import AppRouter from '@/AppRouter';
import FontStyles from '@/AppRouter/FontStyles';

import { defaultTheme } from '@/assets/theme';

import useGoogleAnalytics from '@/hooks/GoogleAnalytics/useGoogleAnalytics';

import { ReferenceDataContextProvider } from '@/context/PlayersContext';
import { StateContextProvider } from '@/context/StateContex';

import Loader from '@/pages/Loader/Loader';

import { AppStyles, GlobalStyles, Reset } from './App.styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ThemeProvider } from 'styled-components';

import { useAuth } from './useAuth';

export const App = () => {
  const loading = useAuth();
  useGoogleAnalytics();

  if (loading) {
    return (
      <div style={{ height: '100vh', width: '100vw' }}>
        <Loader />
      </div>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Reset />
      <ReferenceDataContextProvider>
        <FontStyles />
        <GlobalStyles />
        <StateContextProvider>
          <AppStyles>
            {/* <Layout> */}
            <AppRouter />
            {/* </Layout> */}
          </AppStyles>
        </StateContextProvider>
      </ReferenceDataContextProvider>
    </ThemeProvider>
  );
};
